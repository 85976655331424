<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addMerchant'})">المتاجر</dashboard-page-title>

    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK" :fields="fields" list_url="admin/stores"  :additional-url="`&status=completed`" :reloadData="reloadData"></main-table>

    <b-modal id="suspendedModal" size="lg" title="سبب ايقاف المتجر" style="direction: rtl" centered hide-footer hide-header-close>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="d-flex flex-wrap">
          <b-col>
            <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="handleSuspendMarket">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */ 
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
import marketPlaceServices from '../services/marketPlace'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      reloadData: false,
      saveButton: false,
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'صورة الشعار', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'صورة الغلاف', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              icon: 'ri-eye-fill',
              color: 'success-light',
              text: 'عرض',
              ifNavigate: true,
              routePath: 'show-merchant/:id'
            },
            {
              icon: 'ri-ball-pen-fill',
              color: 'info',
              text: 'تعديل',
              ifNavigate: true,
              routePath: 'edit-marketplace/:id'
            },
            {
              text: 'مسح',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'admin/stores',
              baseUrl: VUE_APP_MARKETPLACE_LINK,  
              titleHeader: 'متجر',
              question: 'هل متأكد أنك تريد مسح هذا المتجر',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد، مسح',
              textCancelButton: 'إلغاء',
              showAlert: true
            },
            {
              text: 'تعليق',
              icon: 'ri-spam-2-fill',
              color: 'danger',
              actionParams: ['id'],
              actionName: 'showSuspensedModal'
            }
          ]
        }
      ]
    }
  },
  methods: {
    showSuspensedModal (data) {
      this.suspendedId = data.id
      this.$bvModal.show('suspendedModal')
    },
    handleSuspendMarket () {
      this.saveButton = true
      marketPlaceServices
        .marketStatus(this.suspendedId, 'suspended', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  ايقاف المتجر بنجاح')
          this.saveButton = false
          this.$bvModal.hide('suspendedModal')
          this.reloadData = true
      })
      this.reloadData = false
    },
  },
  created () {
    this.$root.$on('showSuspensedModal', this.showSuspensedModal)
  }
}
</script>
